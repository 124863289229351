// import { Controller } from "@hotwired/stimulus"
import { Controller } from "stimulus"
import intlTelInput from "intl-tel-input";
// import utils from 'intl-tel-input/build/js/utils.js';
// import utils from "intl-tel-input/build/js/utils.js?1716383386062";
import utils from "intl-tel-input/build/js/utils.js?1724352053124";

// Connects to data-controller="phone"
export default class extends Controller {
  static targets = ["phone", "output"]

  connect() {
    const input = document.querySelector("#phone");
    // const output = this.outputTarget;
    const output = document.querySelector("#output");
    this.phoneTarget.addEventListener('change', this.handleChange);
    this.phoneTarget.addEventListener('keyup', this.handleChange);

    // Проверка, что input существует
    if (!input) {
      console.error("Input element with id 'phone' not found.");
      return;
    }

    // Проверка, что intlTelInput загружен корректно
    if (typeof intlTelInput === 'undefined') {
      console.error("intlTelInput is not defined. Make sure the script is loaded correctly.");
      return;
    }

    this.phoneTarget.type = "tel"
    console.log(intlTelInput);
    console.log(this.phoneTarget);
    this.iti = intlTelInput(this.phoneTarget, {
      customPlaceholder: function() {
        return "777-111-2222";
      },
      utilsScript: utils,
      initialCountry: "kz",
      // separateDialCode: false,
      nationalMode: true,
      // placeholderNumberType:"MOBILE",
      // autoPlaceholder:"polite",
      placeholderNumberType: "aggressive",
      strictMode: true,
      hiddenInput: () => ({ phone: "full_phone", country: "country_code" }),
    })
    console.log(this.iti);
  }

  handleChange = () => {
    const input = this.phoneTarget;
    // console.log(input.value)
    // const input = this.element.querySelector("#phone");
    const output = this.element.querySelector("#output");
    let text;

    if (input.value) {
      text = this.iti.isValidNumberPrecise()
        // ? "Valid number! Full international format: " + this.iti.getNumber()
        ? "Действительный номер!"
        : "Неверный номер - проверьте и измените его";
    } else {
      text = "Номер похоже не действительный";
    }

    output.textContent = text;
  };
}
