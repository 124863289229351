import { Controller } from "stimulus"
import Choices from "choices.js"

// Connects to data-controller="select"
export default class extends Controller {
  connect() {
    this.choices = null;
    this.initChoices();
  }

  initChoices() {
    const element = document.getElementById("choices-element");
    if (element) {
      this.choices = new Choices(element, {
        removeItemButton: true,
        placeholder: true,
        placeholderValue: "Добавить клиента"
      });
    }
  }

  disconnect() {
    if (this.choices) {
      this.choices.destroy();
      this.choices = null;
    }
  }
}
